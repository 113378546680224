import { LogoIcon } from "@cakemembers/components-cake";
import { InstagramIcon, Text } from "@cakemembers/components-core";
import Link from "next/link";

const Links = {
  About: {
    hrefPublic: "/about-cake",
    hrefPrivate: "/account/about-cake",
  },
  FAQs: {
    hrefPublic: "/faq-public",
    hrefPrivate: "/account/faq-private",
  },
  Contact: {
    hrefPublic: "mailto:concierge@cakemembers.com",
    hrefPrivate: "mailto:concierge@cakemembers.com",
  },
  "Terms of Service": {
    hrefPublic: "/terms-of-service",
    hrefPrivate: "/account/terms-of-service",
  },
  "Privacy Policy": {
    hrefPublic: "/privacy-policy",
    hrefPrivate: "/account/privacy-policy",
  },
};

export function Footer({ loggedIn }: { loggedIn?: boolean }) {
  return (
    <footer className="px-4 pt-10 md:pb-5 pb-2 w-full max-w-screen-2xl mx-auto">
      <div className="md:border-t-2 md:border-t-dark/10 flex flex-col md:flex-row items-center justify-between">
        <div className="flex md:gap-1 items-center divide-x-[1px] divide-[#7D766E] md:divide-x-0 pt-2 pb-1">
          {Object.entries(Links).map(([key, value], idx) => (
            <>
              <Link
                className="font-supreme text-[11px] sm:text-xs whitespace-nowrap font-medium uppercase px-1"
                href={loggedIn ? value.hrefPrivate : value.hrefPublic}
              >
                {key}
              </Link>
              {idx < Object.keys(Links).length - 1 && <Spacer />}
            </>
          ))}
        </div>
        <Text
          face="sans"
          size="xs"
          weight="medium"
          className="hidden md:flex pt-1"
        >
          © 2024 CFB Inc. All rights reserved.
        </Text>
      </div>
      <Text
        face="sans"
        size="sm"
        className="md:hidden text-center flex justify-center"
      >
        © 2024 CFB Inc. All rights reserved.
      </Text>
      <div className="flex items-center justify-center md:justify-between w-full py-3 md:py-0">
        <Link
          href="https://www.instagram.com/cake.members/"
          target="_blank"
          className="flex items-center"
        >
          <InstagramIcon />
        </Link>
        <div className="hidden md:flex w-16 h-5">
          <LogoIcon className="w-full h-full" />
        </div>
      </div>
    </footer>
  );
}

function Spacer() {
  return (
    <span className="hidden md:flex font-thin text-xs items-center pb-1">
      •
    </span>
  );
}
