import Link from "next/link";
import { LogoIcon } from "../logos";

import styles from "./AppHeader.module.scss";
import { AppHeaderLink } from "./AppHeaderLink";

export function AppHeader() {
  return (
    <header className={styles.AppHeader}>
      <input className={styles.sideMenu} type="checkbox" id="side-menu" />
      <div>
        {/** container */}
        <div className={styles.navContainer}>
          {/** LOGO */}
          <div className={styles.logoContainer}>
            <Link href="/home" className={styles.logo}>
              <LogoIcon height="100%" width="100%" className="fill-black" />
            </Link>
          </div>
          {/** Hamburger */}
          <label className={styles.hamburger} htmlFor="side-menu">
            <span className={styles.hamburgerLine}></span>
          </label>
        </div>

        {/** Menu */}
        <nav className={styles.nav}>
          <div className={styles.menu}>
            <AppHeaderLink href={"/home"} title="Wallet" />
            <AppHeaderLink
              href={"/home?view=Feed"}
              className={styles["md:hidden"]}
              title="Feed"
            />
            <AppHeaderLink
              href={"/home?view=Profile"}
              className={styles["md:hidden"]}
              title="Profile  "
            />
            <AppHeaderLink href={"/brands"} title="Brands" />
            <AppHeaderLink href={"/account"} title="Account" />
          </div>
        </nav>
      </div>
    </header>
  );
}
