import Link from "next/link";
import { SanityImageServer } from "../images";
import { Heading4 } from "../text";
import classNames from "classnames";
import { SanityImageType } from "..";
import { Caption3 } from "@cakemembers/components-core";

export type CakeCardProps = {
  name: string;
  href?: string;
  value?: number | string;
  backgroundImage?: SanityImageType;
  logoImage?: SanityImageType;
  bottomContent?: React.ReactNode;
  imprint?: React.ReactNode;
  className?: string;
  redeemed?: boolean;
};
export function CakeCard({
  name,
  href,
  value,
  backgroundImage,
  logoImage,
  bottomContent,
  imprint,
  className,
  redeemed,
}: CakeCardProps) {
  const content = (
    <div
      className={classNames(
        `w-full block rounded-md bg-dark aspect-[3.370/2.125] relative shadow-lg overflow-hidden`,
        className
      )}
    >
      {/* BACKGROUND IMAGE */}
      {backgroundImage && (
        <SanityImageServer
          alt={`${name} background image`}
          image={backgroundImage}
          aspectRatio="wallet"
          sizes="(max-width: 425px) 425px, 768px"
          width={768}
          height={(768 * 3) / 2}
          className="w-full h-full object-cover"
        />
      )}
      {/* OVERLAY */}
      <div className="w-full h-full absolute top-0 left-0 bg-black/30 hover:drop-shadow-xl"></div>
      {/* MAIN CARD */}
      <div className="w-full h-full absolute top-0 left-0 flex flex-col">
        {/* HEADER */}
        <div className="h-[20%] @container p-[4%] flex flex-row items-top justify-center text-dark-content ">
          {logoImage && (
            <SanityImageServer
              alt={`${name} logo`}
              image={logoImage}
              width={750}
              height={750}
              style={{
                height: "100%",
                maxWidth: "45%",
                objectFit: "contain",
                objectPosition: "left center",
              }}
              className="invert"
            />
          )}
          <div className="grow" />
          <div className="flex flex-col items-end justify-center">
            <Heading4 className="text-[5cqw]">{value && `$${value}`}</Heading4>
            {redeemed && (
              <span className="uppercase font-sansSerif text-[2.5cqw] leading-tight">
                redeemed
              </span>
            )}
          </div>
        </div>
        {/* BODY */}
        <div className="h-[80%] px-[4%] pb-[4%] flex flex-row">
          {/* BOTTOM */}
          <div className="h-full w-[94%] flex flex-col justify-end grow">
            {bottomContent}
          </div>
          {/* IMPRINT */}
          <div className="h-full  w-[6%] flex flex-col items-end justify-end">
            {imprint}
          </div>
        </div>
      </div>
    </div>
  );

  if (href) {
    return <Link href={href}>{content}</Link>;
  }

  return content;
}
