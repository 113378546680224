import { AppHeader } from "./AppHeader";
import { Footer } from "./Footer";

export function AppShell({ children }: { children: React.ReactNode }) {
  return (
    <>
      <AppHeader />
      <AppContent>{children}</AppContent>
      <Footer loggedIn={true} />
    </>
  );
}

function AppContent({ children }: { children: React.ReactNode }) {
  return <main className="grow">{children}</main>;
}
