import { IconBase, IconBaseProps } from "react-icons/lib";

export function WalletIcon(props: IconBaseProps) {
  return (
    <IconBase viewBox="0 0 32 32" fill="none" {...props}>
      <path
        fill="currentColor"
        d="M7.42188 6.20965C7.92467 6.07295 8.45372 6 8.99981 6H22.9998C23.5459 6 24.075 6.07296 24.5778 6.20965C23.9208 4.89938 22.5654 4 20.9998 4H10.9998C9.43427 4 8.07879 4.89938 7.42188 6.20965Z"
      />
      <path
        fill="currentColor"
        d="M3 16C3 13.7909 4.79086 12 7 12H25C27.2091 12 29 13.7909 29 16V24C29 26.2091 27.2091 28 25 28H7C4.79086 28 3 26.2091 3 24V16Z"
      />
      <path
        fill="currentColor"
        d="M6.99981 10C6.45372 10 5.92467 10.073 5.42188 10.2096C6.07879 8.89938 7.43427 8 8.99981 8H22.9998C24.5654 8 25.9208 8.89938 26.5778 10.2097C26.075 10.073 25.5459 10 24.9998 10H6.99981Z"
      />
    </IconBase>
  );
}
export function BagIcon(props: IconBaseProps) {
  return (
    <IconBase viewBox="0 0 32 32" {...props}>
      <path d="M27.8854 10.564C27.8594 10.2285 27.7076 9.91513 27.4603 9.68688C27.213 9.45862 26.8886 9.33232 26.552 9.33332H21.3334V7.99999C21.3334 6.5855 20.7715 5.22895 19.7713 4.22875C18.7711 3.22856 17.4145 2.66666 16 2.66666C14.5855 2.66666 13.229 3.22856 12.2288 4.22875C11.2286 5.22895 10.6667 6.5855 10.6667 7.99999V9.33332H5.33333C4.9968 9.33232 4.67234 9.45862 4.42504 9.68688C4.17775 9.91513 4.02591 10.2285 4 10.564L4.11469 25.128C4.08639 25.4944 4.13413 25.8627 4.25491 26.2098C4.37569 26.5568 4.5669 26.8752 4.81654 27.1449C5.06618 27.4145 5.36887 27.6297 5.70561 27.7769C6.04235 27.924 6.40587 28 6.77335 28H25.2267C25.5941 28 25.9575 27.924 26.2941 27.777C26.6307 27.6299 26.9334 27.4148 27.183 27.1453C27.4326 26.8758 27.6238 26.5576 27.7447 26.2107C27.8656 25.8638 27.9135 25.4956 27.8854 25.1293V10.564ZM13.3334 7.99999C13.3334 7.29275 13.6143 6.61447 14.1144 6.11437C14.6145 5.61427 15.2928 5.33332 16 5.33332C16.7073 5.33332 17.3855 5.61427 17.8856 6.11437C18.3857 6.61447 18.6667 7.29275 18.6667 7.99999V9.33332H13.3334V7.99999Z" />
    </IconBase>
  );
}

export function TicketIcon(props: IconBaseProps) {
  return (
    <IconBase viewBox="0 0 24 24" {...props}>
      <path d="M20 19H4C3.46957 19 2.96086 18.7893 2.58579 18.4142C2.21071 18.0391 2 17.5304 2 17V14.5C2 14.2348 2.10536 13.9804 2.29289 13.7929C2.48043 13.6054 2.73478 13.5 3 13.5C3.39782 13.5 3.77936 13.342 4.06066 13.0607C4.34196 12.7794 4.5 12.3978 4.5 12C4.5 11.6022 4.34196 11.2206 4.06066 10.9393C3.77936 10.658 3.39782 10.5 3 10.5C2.73478 10.5 2.48043 10.3946 2.29289 10.2071C2.10536 10.0196 2 9.76522 2 9.5V7C2 6.46957 2.21071 5.96086 2.58579 5.58579C2.96086 5.21071 3.46957 5 4 5H20C20.5304 5 21.0391 5.21071 21.4142 5.58579C21.7893 5.96086 22 6.46957 22 7V9.5C22 9.76522 21.8946 10.0196 21.7071 10.2071C21.5196 10.3946 21.2652 10.5 21 10.5C20.6022 10.5 20.2206 10.658 19.9393 10.9393C19.658 11.2206 19.5 11.6022 19.5 12C19.5 12.3978 19.658 12.7794 19.9393 13.0607C20.2206 13.342 20.6022 13.5 21 13.5C21.2652 13.5 21.5196 13.6054 21.7071 13.7929C21.8946 13.9804 22 14.2348 22 14.5V17C22 17.5304 21.7893 18.0391 21.4142 18.4142C21.0391 18.7893 20.5304 19 20 19Z" />
    </IconBase>
  );
}

export function BrandsIcon() {
  return (
    <svg
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.625 0.5H1.875C1.96347 0.5 2 0.563812 2 0.6V1.8C2 1.83619 1.96347 1.9 1.875 1.9H0.625C0.536531 1.9 0.5 1.83619 0.5 1.8V0.6C0.5 0.563812 0.536531 0.5 0.625 0.5ZM0.625 5.3H1.875C1.96347 5.3 2 5.36381 2 5.4V6.6C2 6.63619 1.96347 6.7 1.875 6.7H0.625C0.536531 6.7 0.5 6.63619 0.5 6.6V5.4C0.5 5.36381 0.536531 5.3 0.625 5.3ZM0.625 10.1H1.875C1.96347 10.1 2 10.1638 2 10.2V11.4C2 11.4362 1.96347 11.5 1.875 11.5H0.625C0.536531 11.5 0.5 11.4362 0.5 11.4V10.2C0.5 10.1638 0.536531 10.1 0.625 10.1ZM18.75 1.9H6.25C6.04465 1.9 5.85161 1.8215 5.71238 1.68783C5.57384 1.55484 5.5 1.37891 5.5 1.2C5.5 1.02109 5.57384 0.845162 5.71238 0.712166C5.85161 0.578503 6.04465 0.5 6.25 0.5H18.75C18.9553 0.5 19.1484 0.578503 19.2876 0.712166C19.4262 0.845161 19.5 1.02109 19.5 1.2C19.5 1.37891 19.4262 1.55484 19.2876 1.68783C19.1484 1.8215 18.9553 1.9 18.75 1.9ZM18.75 6.7H6.25C6.04465 6.7 5.85161 6.6215 5.71238 6.48783C5.57384 6.35484 5.5 6.17891 5.5 6C5.5 5.82109 5.57384 5.64516 5.71238 5.51217C5.85161 5.3785 6.04465 5.3 6.25 5.3H18.75C18.9553 5.3 19.1484 5.3785 19.2876 5.51217C19.4262 5.64516 19.5 5.82109 19.5 6C19.5 6.17891 19.4262 6.35484 19.2876 6.48783C19.1484 6.6215 18.9553 6.7 18.75 6.7ZM18.75 11.5H6.25C6.04465 11.5 5.85161 11.4215 5.71238 11.2878C5.57384 11.1548 5.5 10.9789 5.5 10.8C5.5 10.6211 5.57384 10.4452 5.71238 10.3122C5.85161 10.1785 6.04465 10.1 6.25 10.1H18.75C18.9553 10.1 19.1484 10.1785 19.2876 10.3122C19.4262 10.4452 19.5 10.6211 19.5 10.8C19.5 10.9789 19.4262 11.1548 19.2876 11.2878C19.1484 11.4215 18.9554 11.5 18.75 11.5Z" />
    </svg>
  );
}

export function MembersIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 9.47368C8.98528 9.47368 11 7.35293 11 4.73684C11 2.12076 8.98528 0 6.5 0C4.01472 0 2 2.12076 2 4.73684C2 7.35293 4.01472 9.47368 6.5 9.47368Z"
        fill="#6B7280"
      />
      <path
        d="M8 10.5263H5C3.67441 10.528 2.40356 11.083 1.46622 12.0697C0.528882 13.0564 0.00158786 14.3941 0 15.7895V18.9474C0 19.2265 0.105357 19.4943 0.292893 19.6917C0.48043 19.8891 0.734784 20 1 20H12C12.2652 20 12.5196 19.8891 12.7071 19.6917C12.8946 19.4943 13 19.2265 13 18.9474V15.7895C12.9984 14.3941 12.4711 13.0564 11.5338 12.0697C10.5964 11.083 9.32559 10.528 8 10.5263Z"
        fill="#6B7280"
      />
      <path
        d="M19 7.36842H17V5.26316C17 4.98398 16.8946 4.71624 16.7071 4.51884C16.5196 4.32143 16.2652 4.21053 16 4.21053C15.7348 4.21053 15.4804 4.32143 15.2929 4.51884C15.1054 4.71624 15 4.98398 15 5.26316V7.36842H13C12.7348 7.36842 12.4804 7.47932 12.2929 7.67673C12.1054 7.87414 12 8.14188 12 8.42105C12 8.70023 12.1054 8.96797 12.2929 9.16538C12.4804 9.36278 12.7348 9.47368 13 9.47368H15V11.5789C15 11.8581 15.1054 12.1259 15.2929 12.3233C15.4804 12.5207 15.7348 12.6316 16 12.6316C16.2652 12.6316 16.5196 12.5207 16.7071 12.3233C16.8946 12.1259 17 11.8581 17 11.5789V9.47368H19C19.2652 9.47368 19.5196 9.36278 19.7071 9.16538C19.8946 8.96797 20 8.70023 20 8.42105C20 8.14188 19.8946 7.87414 19.7071 7.67673C19.5196 7.47932 19.2652 7.36842 19 7.36842Z"
        fill="#6B7280"
      />
    </svg>
  );
}

export function CakePresentIcon(props: IconBaseProps) {
  return (
    <IconBase viewBox="0 0 72 72" fill="none" strokeWidth="1.5" {...props}>
      <path d="M36.922 72V29.524C39.6741 32.8965 42.2069 38.3877 42.9378 44.6433C43.94 53.2212 43.2697 58.996 41.3466 62.165L46.8935 67.3827C47.932 63.9555 48.2243 59.4129 47.6022 54.0041C46.5167 44.5643 43.8945 36.5577 39.4684 30.9794C49.3759 37.9805 54.8341 38.2388 54.8341 38.2388V28.3637H72V72H36.922ZM0 28.3637H17.1659V38.2388C17.1659 38.2388 22.6241 37.9805 32.5311 30.9794C28.1049 36.5577 25.4833 44.5643 24.3978 54.0041C23.7757 59.4129 24.068 63.9555 25.107 67.3827L30.6534 62.165C28.7303 58.996 28.06 53.2212 29.0622 44.6433C29.7931 38.3877 32.3253 32.8965 35.078 29.524V72H0V28.3637ZM35.078 2.99007e-06V25.675C33.3465 23.8255 31.4049 21.9781 29.1791 20.1151C22.3506 14.3987 17.1659 14.0885 17.1659 14.0885V26.5191H0V2.99007e-06H35.078ZM36.922 27.4141C38.2919 25.8932 39.8495 24.2115 41.6725 22.6852C45.1008 19.8157 48.2146 18.7399 49.9834 18.3381C50.7669 18.16 51.5102 18.7583 51.5102 19.5617V33.6859C51.5102 34.131 51.1215 34.477 50.6802 34.4196C48.8627 34.1841 44.1268 33.1813 36.922 27.4227V27.4141ZM35.078 27.4227C27.8726 33.1813 23.1373 34.1841 21.3192 34.4196C20.878 34.477 20.4892 34.131 20.4892 33.6859V19.5617C20.4892 18.7583 21.2331 18.16 22.016 18.3381C23.7854 18.7399 26.8992 19.8157 30.3275 22.6852C32.1505 24.2115 33.7081 25.8932 35.078 27.4141V27.4227ZM72 26.5191H54.8341V14.0885C54.8341 14.0885 49.6488 14.3987 42.8204 20.1151C40.5945 21.9781 38.653 23.8255 36.922 25.675V2.99007e-06H72V26.5191Z" />
    </IconBase>
  );
}
