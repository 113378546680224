import classNames from "classnames";
import { LogoIcon } from "../logos";
import { Footer } from "./Footer";

export function PublicContainer({
  children,
  displayLogo = true,
  displayFooter = true,
  dark = false,
  headerItem,
  center = false,
  centerVertically = false,
  mainClassname,
  mainPadding,
}: {
  children: React.ReactNode;
  displayLogo?: boolean;
  displayFooter?: boolean;
  headerItem?: React.ReactNode;
  dark?: boolean;
  center?: boolean;
  centerVertically?: boolean;
  mainClassname?: string;
  mainPadding?: string;
}) {
  const className = classNames(
    "w-full h-full min-h-[100dvh] flex flex-col items-center",
    "transition transition-colors duration-1000 ease-in-out",
    dark && "darkSection bg-neutral text-neutral-content"
  );
  return (
    <div className={className}>
      {(headerItem || displayLogo) && (
        <header className="w-full p-4">
          <div className="w-full flex items-center justify-center">
            {displayLogo && (
              <div className="w-fit">
                <LogoIcon
                  height={36}
                  width={129}
                  className={classNames(dark ? "fill-white" : "fill-dark")}
                />
              </div>
            )}
            <span className="grow"></span>
            {headerItem}
          </div>
        </header>
      )}
      <main
        className={classNames(
          mainClassname,
          mainPadding || "px-4 pb-4",
          "w-full h-full grow flex flex-col items-center container",
          {
            "justify-center": center || centerVertically,
          }
        )}
      >
        {children}
      </main>
      {displayFooter && <Footer />}
    </div>
  );
}
