import { Heading4, SanityImageType } from "..";
import { CakeCard } from "./CakeCard";

export function MembershipCardTemplate({
  cakeLogo,
  membershipCardBackground,
  href,
  className,
  bottomContent,
}: {
  cakeLogo?: SanityImageType;
  membershipCardBackground: SanityImageType;
  href?: string;
  className?: string;
  bottomContent?: React.ReactNode;
}) {
  return (
    <CakeCard
      name={"Membership Card"}
      href={href}
      className={className}
      logoImage={cakeLogo}
      backgroundImage={membershipCardBackground}
      bottomContent={bottomContent}
    />
  );
}
